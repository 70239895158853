import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import AppTile from '../../components/AppTile';
import SmallAppTile from '../../components/SmallAppTile';
import { CommonContext } from '../../context';

export const query = graphql`
  query allAppListQuery {
    allAppList {
      nodes {
        isFeatured
        appName
        route
        description
        font
        theme
        heroImageFile {
          childImageSharp {
            gatsbyImageData(width: 700, placeholder: NONE, layout: CONSTRAINED)
          }
        }
        listImageFile {
          childImageSharp {
            gatsbyImageData(width: 700, placeholder: NONE, layout: CONSTRAINED)
          }
        }
        smallImageFile {
          childImageSharp {
            gatsbyImageData(width: 700, placeholder: NONE, layout: CONSTRAINED)
          }
        }
        downloadLinks {
          playStore
          huawei
          appStore
          mac
          windows
          web
        }
      }
    }
  }
`;

function OurApps(props) {
  const appsList = props.data.allAppList.nodes;
  const featuredApps = appsList.filter((item) => item.isFeatured);
  const topApp = featuredApps[0];
  delete featuredApps[0];
  topApp.imageFile = topApp.listImageFile;
  const topAppMobile = { ...topApp };
  topAppMobile.imageFile = topAppMobile.smallImageFile;

  return (
    <CommonContext.Provider value={{ location: props.location }}>
      <Layout
        title="Our Apps"
        image={topAppMobile.imageFile.url}
        location={props.location}
      >
        <AppTile
          appData={topAppMobile}
          index={0}
          className="text-center md:hidden"
        />
        <AppTile
          appData={topApp}
          index={0}
          className="hidden m-auto md:block"
        />
        <div className="text-center md:grid md:grid-cols-2">
          {featuredApps.map(function (item, index) {
            item.imageFile = item.smallImageFile;
            return (
              <React.Fragment key={index}>
                <div className="md:hidden">
                  <AppTile appData={item} index={index} />
                </div>
                <div className="hidden md:block">
                  <SmallAppTile appData={item} />
                </div>
              </React.Fragment>
            );
          })}
          {appsList.map((appData, index) =>
            appData.isFeatured ? null : (
              <SmallAppTile key={index} appData={appData} />
            ),
          )}
        </div>
      </Layout>
    </CommonContext.Provider>
  );
}

OurApps.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default OurApps;
