import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import DownloadLinkDropdown from './DownloadLinkDropdown';
import HeroTitle from './HeroTitle';
import FeaturedStoreLinkButton from './FeaturedStoreLinkButton';
import { Image } from './image';

import GooglePlay from '../../static/icons/google-play-store.svg';
import WebBrowser from '../../static/icons/web-browser.svg';
import Apple from '../../static/icons/apple-icon.svg';
import { extractFileName } from '../utils';
import { Modal } from './modal';
import { PlayIcon } from '../custom-icons';

function AppTile(props) {
  const { appData, index, isSingleAppPage, className } = props;
  const [play, setPlay] = useState(false);
  const url = play
    ? `${appData.videoEmbedUrl}&autoplay=1`
    : appData.videoEmbedUrl;

  const getBgClass = (theme) => {
    switch (theme) {
      case 'primary':
        return 'bg-primary';
      case 'neutral':
        return 'bg-neutral';
      case 'secondary':
        return 'bg-secondary';
      case 'brown':
        return 'bg-brown';
      case 'accent-1':
        return 'bg-accent-1';
      default:
        return '';
    }
  };

  const WrapWithLinkOrNot = ({ appData, children }) => {
    if (!isSingleAppPage) {
      return (
        <div className="relative">
          {children}
          <Link
            to={`/apps/${appData.route}`}
            className="absolute top-0 bottom-0 left-0 right-0 cursor-pointer"
            style={{ zIndex: 3 }}
          />
        </div>
      );
    } else {
      return <div>{children}</div>;
    }
  };

  return (
    <WrapWithLinkOrNot appData={appData}>
      <div
        className={`pl-6 pr-6 md:pl-24 md:pr-24 flex justify-center items-center w-full ${
          index % 2 === 0 ? 'bg-neutral' : 'bg-neutral-1'
        } ${className}`}
      >
        <div className="w-full max-w-6xl m-auto md:grid md:grid-cols-2 md:gap-8">
          <div className="pb-6 text-center md:col-span-1 md:text-left pt-14 md:py-24 ">
            {!isSingleAppPage && (
              <div
                className={`${getBgClass(appData.theme)} ${
                  appData.font == 'bangla'
                    ? 'font-bangla-semiBold'
                    : 'font-semiBold'
                } w-auto inline-block text-xl md:text-3xl text-neutral font-regular px-2 md:px-4 py-1`}
              >
                {appData.appName}
              </div>
            )}
            <HeroTitle
              text={isSingleAppPage ? appData.appName : appData.description}
              className={`${
                appData.font == 'bangla'
                  ? 'font-bangla-semiBold'
                  : 'font-semiBold'
              } `}
            />
            {isSingleAppPage && (
              <div className="mt-8">
                <div className="grid grid-cols-2 gap-4 mx-auto w-max md:m-0 ">
                  {appData.downloadLinks.appStore && (
                    <FeaturedStoreLinkButton
                      className="w-32 md:w-40 "
                      icon={Apple}
                      link={{
                        label: 'App Store',
                        url: appData.downloadLinks.appStore,
                      }}
                    />
                  )}
                  {appData.downloadLinks.playStore && (
                    <FeaturedStoreLinkButton
                      className="w-32 md:w-40 "
                      icon={GooglePlay}
                      link={{
                        label: 'Play store',
                        url: appData.downloadLinks.playStore,
                      }}
                    />
                  )}
                  {appData.downloadLinks.web && (
                    <FeaturedStoreLinkButton
                      className="w-32 md:w-40 "
                      icon={WebBrowser}
                      title="Visit on"
                      link={{
                        label: 'Web App',
                        url: appData.downloadLinks.web,
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="flex justify-center font-semiBold text-primary md:justify-start">
              {!isSingleAppPage && (
                <React.Fragment>
                  <Link
                    to={`/apps/${appData.route}`}
                    className="mr-7 md:mr-11 link-underline-primary"
                    style={{ zIndex: 4 }}
                  >
                    Learn More{' '}
                    <span className="hidden">About {appData.appName}</span>
                  </Link>
                  <DownloadLinkDropdown downloadLinks={appData.downloadLinks} />
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="mt-10 md:mt-0 md:col-span-1">
            {play && appData.videoEmbedUrl && (
              <Modal
                isOpen={play}
                onClose={() => setPlay(false)}
                className="max-w-3xl"
              >
                <iframe
                  className="w-full border-0 rounded-lg aspect-video"
                  src={url}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowfullscreen="allowfullscreen"
                />
              </Modal>
            )}
            <div className={`relative h-full w-full`}>
              {appData.videoEmbedUrl && (
                <div className="absolute inset-0 z-10 flex items-center justify-center">
                  <div className="relative flex items-center justify-center w-max h-max">
                    <div className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-primary-3" />
                    <button
                      className="z-10 w-16 h-16 rounded-full shadow-xl lg:w-20 lg:h-20 bg-primary-2"
                      onClick={() => setPlay(!play)}
                    >
                      <PlayIcon className="w-8 h-8 m-auto sm:w-10 sm:h-10 lg:w-12 lg:h-12 text-neutral-7" />
                    </button>
                  </div>
                </div>
              )}

              <Image
                alt={extractFileName(appData.heroImage)}
                imageFile={appData.imageFile}
                className="m-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </WrapWithLinkOrNot>
  );
}

AppTile.propTypes = {
  children: PropTypes.node,
  appData: PropTypes.object,
  index: PropTypes.number,
  isSingleAppPage: PropTypes.bool,
  className: PropTypes.string,
};

AppTile.defaultProps = {
  isSingleAppPage: false,
  className: '',
};

export default AppTile;
