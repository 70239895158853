import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from './Dropdown';
import { appStoreNames } from '../constants';
import { ChevronDown } from '../custom-icons';

function DownloadLinkDropdown({ downloadLinks }) {
  const dropdownMenuData = [];
  Object.keys(downloadLinks).forEach((key) => {
    if (downloadLinks[key] !== null) {
      dropdownMenuData.push({
        route: downloadLinks[key],
        title: appStoreNames.find((item) => item.slug === key).label,
      });
    }
  });

  return (
    <Dropdown
      component={
        <div
          className="relative inline-flex cursor-pointer"
          style={{ zIndex: 4 }}
        >
          <span className="link-underline-primary">Download now </span>
          <span>
            <ChevronDown className="text-secondary" />
          </span>
        </div>
      }
      menu={dropdownMenuData}
    />
  );
}

DownloadLinkDropdown.propTypes = {
  downloadLinks: PropTypes.object.isRequired,
};

export default DownloadLinkDropdown;
