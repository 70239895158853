import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import DownloadLinkDropdown from './DownloadLinkDropdown';
import { Image } from './image';

function SmallAppTile({ appData }) {
  return (
    <Link to={`${appData.route}`}>
      <div className="bg-neutral-1 m-6 flex flex-col">
        <div
          className={`mt-4 ${
            appData.font == 'bangla' ? 'font-bangla-semiBold' : 'font-semiBold'
          } text-4xl md:text-6xl text-center px-8 h-auto xl:h-32 pt-6 flex-grow`}
        >
          {appData.appName}
        </div>
        <div className="font-semiBold mt-8 text-primary flex justify-center">
          <Link
            to={`${appData.route}`}
            className="mr-7 md:mr-11 link-underline-primary"
          >
            Learn More <span className="hidden">About {appData.appName}</span>
          </Link>
          <DownloadLinkDropdown downloadLinks={appData.downloadLinks} />
        </div>
        <div className="mt-10">
          <Image
            imageFile={appData.smallImageFile}
            className="m-auto md:w-56"
          />
        </div>
      </div>
    </Link>
  );
}

SmallAppTile.propTypes = {
  appData: PropTypes.object.isRequired,
};

export default SmallAppTile;
